import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "utils/auth/protectedRoute/ProtectedRoute";
import AdminProtectedRoute from "utils/auth/protectedRoute/AdminProtectedRoute";

// VideoDocument is not lazy loaded refer to slid_web issue #244
import VideoDocument from "components/VideoDocument/VideoDocument";
import env from "config/env";

// lazy loading components
const OauthRedirect = lazy(() => import("components/auth/OauthRedirect"));
const DocumentEditor = lazy(() => import("components/Document/DocumentEditor"));
const History = lazy(() => import("components/history/History"));
const DesktopAuthComponent = lazy(() => import("components/auth/DesktopAuthComponent"));

// pages
const Pricing = lazy(() => import("pages/Pricing/NewPricingPage"));
const LegacyPricing = lazy(() => import("pages/LegacyPricing"));
const ExpertPricing = lazy(() => import("pages/ExpertPricing"));
const MyNotes = lazy(() => import("pages/MyNotes"));
const SlidDemoPage = lazy(() => import("pages/SlidDemoPage/SlidDemoPage"));
const VideoNoteDemoPage = lazy(() => import("pages/SlidDemoPage/VideoNoteDemoPage"));
const AuthPage = lazy(() => import("pages/Auth/AuthPage"));
const AuthRequired = lazy(() => import("pages/Auth/AuthRequired"));
const HowToSlidDesktop = lazy(() => import("pages/HowToSlidDesktop/HowToSlidDesktop"));
const InstallGuidePage = lazy(() => import("pages/InstallGuidePage"));
const ViewNoteOption = lazy(() => import("pages/ViewNoteOption/ViewNoteOption"));
const AccountTabPage = lazy(() => import("pages/mobile/AccountTabPage/AccountTabPage"));
const Page404 = lazy(() => import("pages/error/404"));
const AgeInterestForm = lazy(() => import("pages/AgeInterestFormPage/AgeInterestFormPage"));
const InstallSlid = lazy(() => import("pages/InstallSlid"));
const WebVideoDocument = lazy(() => import("pages/WebVideoDocument"));
const SlidExpert = lazy(() => import("pages/SlidExpert"));
const SlidOnboardingPage = lazy(() => import("pages/SlidOnboarding/SlidOnboardingPage"));
const ExtensionInstalledPage = lazy(() => import("pages/ExtensionInstalled/ExtensionInstalledPage"));
const ExtensionAuthRedirectPage = lazy(() => import("pages/Auth/ExtensionAuthRedirectPage"));
const ClearingCookiePage = lazy(() => import("pages/ClearingCookiePage"));
const SlidPocketEditor = lazy(() => import("pages/SlidPocket"));
const SlidMobileEditor = lazy(() => import("pages/SlidMobileEditor"));
const StudyChallengeAdminPage = lazy(() => import("pages/StudyChallengeAdminPage"));
const MobileAppRedirectPage = lazy(() => import("pages/MobileAppRedirectPage"));

const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;

const SlidRouter = (props) => {
  const { lang, auth_type, serverErrorHandler } = props;

  return (
    <Switch>
      <Route
        exact={true}
        path={`/oauth_redirect`}
        render={(props) => {
          return (
            <OauthRedirect
              {...props}
              lang={lang}
              auth_type={auth_type}
              serverErrorHandler={(error) => {
                serverErrorHandler(error);
              }}
            />
          );
        }}
      />
      <Route
        exact={true}
        path={`/pricing`}
        render={(props) => {
          return <Pricing {...props} />;
        }}
      />
      <Route
        exact={true}
        path={`/legacy-pricing`}
        render={(props) => {
          return <LegacyPricing {...props} />;
        }}
      />
      <Route
        exact={true}
        path={`/promotions`}
        render={(props) => {
          return <ExpertPricing {...props} />;
        }}
      />
      <Route
        exact={true}
        path={`/terms_of_service`}
        render={(props) => {
          if (lang === "ko") {
            window.location.href = `https://slid.oopy.io/9e1bf533-7687-4270-984c-f8f9644aa29f`;
          } else {
            window.location.href = `https://slid.oopy.io/14afbc26-429f-419f-aa58-0ac8cbe05119`;
          }

          return null;
        }}
      />
      <Route
        exact={true}
        path={`/contact`}
        render={(props) => {
          window.location.href = `https://slid.channel.io/`;
          return null;
        }}
      />
      <Route
        exact={true}
        path={`/univ/:univ`}
        render={(props) => {
          return <Redirect to={`?ref=univ_${props.match.params.univ}`} />;
        }}
      />
      <Route
        exact={true}
        path={`/tag/ko/:query`}
        render={(props) => {
          return <Redirect to={`?ref=query_${props.match.params.query}`} />;
        }}
      />
      <Route
        exact={true}
        path={`/tag/en/:query`}
        render={(props) => {
          return <Redirect to={`?ref=query_${props.match.params.query}`} />;
        }}
      />
      <Route
        path={`/ko`}
        render={(props) => {
          return <Redirect to={`/`} />;
        }}
      />
      <Route
        path={`/en`}
        render={(props) => {
          return <Redirect to={`/`} />;
        }}
      />

      <Route
        path={`/auth-required`}
        render={(props) => {
          return <AuthRequired />;
        }}
      />
      <Route
        path={`/sign-up`}
        render={(props) => {
          return <AuthPage stage={"SIGNUP"} />;
        }}
      />
      <Route
        path={`/sign-in`}
        render={(props) => {
          return <AuthPage stage={"SIGNIN"} />;
        }}
      />
      <Route
        exact={true}
        path={`/desktop_auth`}
        render={(props) => {
          return <DesktopAuthComponent />;
        }}
      />
      <Route
        exact={true}
        path={`/extension_install`}
        render={(props) => {
          return <ExtensionInstalledPage />;
        }}
      />
      <Route
        exact={true}
        path={`/extension-auth-redirect`}
        render={(props) => {
          return <ExtensionAuthRedirectPage />;
        }}
      />
      <Route
        exact={true}
        path={`/demo`}
        render={(props) => {
          return <SlidDemoPage />;
        }}
      />
      <Route
        exact={true}
        path={`/video-note-demo`}
        render={(props) => {
          return <VideoNoteDemoPage />;
        }}
      />
      <Route
        exact={true}
        path={`/how_to_slid`}
        render={(props) => {
          return <SlidOnboardingPage />;
        }}
      />
      <Route
        exact={true}
        path={`/extension-install-guide`}
        render={(props) => {
          return <InstallGuidePage />;
        }}
      />
      <Route
        exact={true}
        path={`/how_to_slid_desktop`}
        render={(props) => {
          return <HowToSlidDesktop />;
        }}
      />
      <Route
        path={`/share/docs/:documentKey`}
        render={(props) => {
          window.location.replace(`${SLID_WEB_SITE_URL}/share/docs/${props.match.params.documentKey}`);
          return null;
        }}
      />
      <Route
        path={`/share/vdocs/:documentKey`}
        render={(props) => {
          window.location.replace(`${SLID_WEB_SITE_URL}/share/vdocs/${props.match.params.documentKey}`);
          return null;
        }}
      />
      <Route
        exact={true}
        path={`/slid-expert`}
        render={(props) => {
          return <SlidExpert />;
        }}
      />
      <Route
        exact={true}
        path={"/clear-cookie"}
        render={(props) => {
          return <ClearingCookiePage />;
        }}
      />

      {/* auth needed */}
      <ProtectedRoute
        exact={true}
        path={["/", "/docs"]}
        render={(props) => {
          return <MyNotes {...props} cookies={props.cookies} />;
        }}
      />
      <ProtectedRoute
        path={`/account`}
        render={() => {
          return <AccountTabPage />;
        }}
      />
      <ProtectedRoute
        path={`/vdocs/:documentKey`}
        render={(props) => {
          return <VideoDocument {...props} documentKey={props.match.params.documentKey} />;
        }}
      />
      <ProtectedRoute
        path={`/video-note/new`}
        render={(props) => {
          return <WebVideoDocument {...props} />;
        }}
      />
      <ProtectedRoute
        path={`/docs/:documentKey`}
        render={(props) => {
          return <DocumentEditor {...props} documentKey={props.match.params.documentKey} />;
        }}
      />
      <ProtectedRoute
        path={`/note/options/:documentKey`}
        render={(props) => {
          return <ViewNoteOption {...props} documentKey={props.match.params.documentKey} />;
        }}
      />
      <ProtectedRoute
        exact={true}
        path={`/history/:documentKey`}
        render={(props) => {
          return <History {...props} documentKey={props.match.params.documentKey} />;
        }}
      />
      <AdminProtectedRoute
        exact={true}
        path={`/study-challenge-admin`}
        render={(props) => {
          return <StudyChallengeAdminPage {...props} />;
        }}
      />
      <Route
        exact={true}
        path={`/lectures`}
        render={(props) => {
          return <Redirect to={`/docs`} />;
        }}
      />
      <Route
        exact={true}
        path={`/download`}
        render={(props) => {
          window.location.assign(`${SLID_WEB_SITE_URL}/download`);
          return false;
        }}
      />
      <Route
        path={`/lectures/:lectureId`}
        render={(props) => {
          return <Redirect to={`/docs`} />;
        }}
      />
      <Route
        path={"/user-information-form"}
        render={() => {
          return <AgeInterestForm />;
        }}
      />
      <Route
        path={"/install-slid"}
        render={() => {
          return <InstallSlid />;
        }}
      />
      <Route
        path={"/plan-upgrade-required"}
        render={() => {
          return <Redirect to={`/sign-in`} />;
        }}
      />
      <Route
        path={"/slid-pocket-editor"}
        render={(props) => {
          return <SlidPocketEditor />;
        }}
      />
      <Route
        path={"/slid-mobile-editor"}
        render={(props) => {
          return <SlidMobileEditor />;
        }}
      />
      <Route
        path={"/mobile-app-redirect"}
        render={(props) => {
          return <MobileAppRedirectPage />;
        }}
      />
      <Route
        render={(props) => {
          return <Page404 />;
        }}
      />
    </Switch>
  );
};

export default SlidRouter;
